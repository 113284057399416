import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Button from 'components/core/Button';
import Input from 'components/core/Input';

import { dataShape, styleShape } from '../../utils';
import Logo from '../Logo';
import styles from '../../styles.module.scss';

function StepIdentifier({ data, style, onChange, onSubmit }) {
  return (
    <form onSubmit={onSubmit} style={style} className={styles.form}>
      <Logo />
      <div className={classNames(styles.row, styles.title)}>
        Login
      </div>
      <div className={classNames(styles.row, styles.description)}>
        Sign in with your email address
      </div>
      <div className={classNames(styles.row, styles.input)}>
        <Input
          required
          autoFocus
          type="email"
          name="email"
          id="email"
          tabIndex={0}
          value={data.email}
          isError={data.isError}
          disabled={data.isLoading}
          onChange={onChange}
        />
      </div>
      <Button
        isLoading={data.isLoading}
        tabIndex={0}
        type="submit"
        theme="tertiary"
        disabled={data.isError}
        className={classNames(styles.row, styles.button)}
        loaderClassName={styles.loader}
      >
        <span className={classNames(styles.text, data.isLoading && styles.isLoading)}>
          Next
        </span>
      </Button>
    </form>
  );
}

StepIdentifier.propTypes = {
  data: dataShape.isRequired,
  style: styleShape,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default StepIdentifier;

export const SUPPORT_EMAIL = 'support@capitolcanary.com';

export const DEMO_TEXT = 'Want your company to have access to Intelligence?';
export const DEMO_URL = 'https://capitolcanary.com/schedule-a-demo/';
export const FORGOT_URL = '/reset_password';
export const LOGIN_URL = '/login';
export const MAIN_URL = '/';
export const CHECK_SSO_URL = '/users/check_sso';

export const STEP_IDENTIFIER = 'identifier';
export const STEP_PASSWORD = 'password';
export const STEP_FORGOT = 'forgot';

export const initialData = {
  isLoading: false,
  isError: false,
  email: '',
  name: '',
  password: '',
  step: STEP_IDENTIFIER,
};

import React from 'react';
import classNames from 'classnames';

import styles from '../../styles.module.scss';

function Logo() {
  return (
    <div className={classNames(styles.row, styles.logo)}>
      <img className={styles.icon} src="/images/intelligence-icon-light.png" alt="Intelligence logo" />
      <img className={styles.text} src="/images/intelligence-logo.svg" alt="Intelligence logo text" />
    </div>
  );
}

export default Logo;

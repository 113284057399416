import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Avatar from 'components/core/Avatar';
import Button from 'components/core/Button';
import Input from 'components/core/Input';
import Link from 'components/core/Link';
import RememberUserService from 'components/Login/utils/remember-user-service';

import { dataShape, styleShape } from '../../utils';
import Logo from '../Logo';
import styles from '../../styles.module.scss';

function StepPassword({ data, style, onChange, onSubmit, onReturn, onForgot }) {
  useEffect(() => {
    onChange({ target: { value: '' } });
  }, []);

  const userFirstName = RememberUserService.getFirstName(data.email);
  const userProfileImgUrl = RememberUserService.getProfileImgUrl(data.email);

  return (
    <form onSubmit={onSubmit} className={styles.form} style={style}>
      <Logo />
      <Button
        tabIndex={0}
        type="button"
        theme="secondary"
        customIcon={<i className="fa fa-times" />}
        className={classNames(styles.row, styles.profile)}
        iconClassName={styles.icon}
        onClick={onReturn}
      >
        <Avatar
          avoidDefaultIcon
          isClickable={false}
          className={styles.avatar}
          name={data.email}
          photoUrl={userProfileImgUrl}
        />
        {data.email}
      </Button>
      <div className={classNames(styles.row, styles.title)}>
        Hi <span className={styles.name}>{userFirstName}</span>
      </div>
      <div className={classNames(styles.row, styles.description)}>
        Please enter your password
      </div>
      <Input
        required
        autoFocus
        tabIndex={0}
        type="password"
        name="password"
        id="password"
        value={data.password}
        isError={data.isError}
        disabled={data.isLoading}
        className={classNames(styles.row, styles.input)}
        onChange={onChange}
      />
      <Button
        isLoading={data.isLoading}
        tabIndex={0}
        type="submit"
        theme="tertiary"
        disabled={data.isError}
        className={classNames(styles.row, styles.button)}
        loaderClassName={styles.loader}
      >
        <span className={classNames(styles.text, data.isLoading && styles.isLoading)}>
          Sign-in
        </span>
      </Button>
      <Link
        tabIndex={0}
        theme="link"
        className={classNames(styles.row, styles.link)}
        onClick={onForgot}
      >
        I forgot my password
      </Link>
    </form>
  );
}

StepPassword.propTypes = {
  data: dataShape.isRequired,
  style: styleShape,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onReturn: PropTypes.func.isRequired,
  onForgot: PropTypes.func.isRequired,
};

export default StepPassword;

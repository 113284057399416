import PropTypes from 'prop-types';

import { stringNumberType } from 'utils/shapes';

export const dataShape = PropTypes.shape({
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  step: PropTypes.string.isRequired,
});

export const styleShape = PropTypes.objectOf(
  stringNumberType
);

import React from 'react';
import classNames from 'classnames';

import FadeTransition from './components/FadeTransition';
import StepIdentifier from './components/StepIdentifier';
import StepPassword from './components/StepPassword';
import StepForgot from './components/StepForgot';
import WarningBanner from './components/WarningBanner';
import useLogin from './utils/useLogin';
import {
  STEP_IDENTIFIER,
  STEP_PASSWORD,
  STEP_FORGOT,
} from './utils';
import styles from './styles.module.scss';

export default function Login() {
  const {
    data,
    year,
    onEmailChange,
    onPassChange,
    onEmailSubmit,
    onPassSubmit,
    onForgotSubmit,
    onGoToStart,
    onGoToForgot,
  } = useLogin();

  const steps = {
    [STEP_IDENTIFIER]: (
      <StepIdentifier
        data={data}
        onChange={onEmailChange}
        onSubmit={onEmailSubmit}
      />
    ),
    [STEP_PASSWORD]: (
      <StepPassword
        data={data}
        onChange={onPassChange}
        onSubmit={onPassSubmit}
        onReturn={onGoToStart}
        onForgot={onGoToForgot}
      />
    ),
    [STEP_FORGOT]: (
      <StepForgot
        data={data}
        onSubmit={onForgotSubmit}
        onReturn={onGoToStart}
      />
    ),
  };

  const contentNode = steps[data.step];
  const containerClassNames = classNames(
    styles.container,
    data.step === STEP_PASSWORD && styles.canaryTreePassword,
    data.step === STEP_FORGOT && styles.canaryTreeForgot
  );
  const sectionClassNames = classNames(
    styles.login,
    data.step === STEP_IDENTIFIER && styles.canary
  );

  return (
    <div className={containerClassNames}>
      <WarningBanner className={sectionClassNames} />

      <section className={sectionClassNames}>
        <FadeTransition step={data.step}>
          {contentNode}
        </FadeTransition>
      </section>
      <footer className={styles.footer}>
        © {year} Quorum Analytics, LLC.
      </footer>
    </div>
  );
}

export function transformCheckForBE({ email }) {
  return {
    email,
  };
}

export function transformLoginForBE({ email, password }) {
  return {
    user: {
      email,
      password,
      remember_me: true,
    },
  };
}

export function transformForgotForBE({ email }) {
  return {
    user: {
      email,
    },
  };
}

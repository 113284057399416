import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Avatar from 'components/core/Avatar';
import Button from 'components/core/Button';
import RememberUserService from 'components/Login/utils/remember-user-service';

import { dataShape, styleShape } from '../../utils';
import Logo from '../Logo';
import styles from '../../styles.module.scss';

function StepForgot({ data, style, onSubmit, onReturn }) {
  const userProfileImgUrl = RememberUserService.getProfileImgUrl(data.email);

  return (
    <form onSubmit={onSubmit} className={styles.form} style={style}>
      <Logo />
      <Button
        tabIndex={0}
        type="button"
        theme="secondary"
        customIcon={<i className="fa fa-times" />}
        className={classNames(styles.row, styles.profile)}
        iconClassName={styles.icon}
        onClick={onReturn}
      >
        <Avatar
          avoidDefaultIcon
          isClickable={false}
          className={styles.avatar}
          name={data.email}
          photoUrl={userProfileImgUrl}
        />
        {data.email}
      </Button>
      <div className={classNames(styles.row, styles.title)}>
        Forgot Password
      </div>
      <div className={classNames(styles.row, styles.description)}>
        We will send a reset password email to <br /><b>{data.email}</b>
      </div>
      <Button
        isLoading={data.isLoading}
        tabIndex={0}
        type="button"
        theme="tertiary"
        className={classNames(styles.row, styles.button)}
        loaderClassName={styles.loader}
        onClick={onSubmit}
      >
        <span className={classNames(styles.text, data.isLoading && styles.isLoading)}>
          Confirm
        </span>
      </Button>
    </form>
  );
}

StepForgot.propTypes = {
  data: dataShape.isRequired,
  style: styleShape,
  onSubmit: PropTypes.func.isRequired,
  onReturn: PropTypes.func.isRequired,
};

export default StepForgot;

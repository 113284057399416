import { postJson } from 'utils/api';

import { transformCheckForBE, transformLoginForBE, transformForgotForBE } from './transformers';
import { LOGIN_URL, FORGOT_URL, CHECK_SSO_URL } from './constants';

function check(data) {
  const ajax = postJson(CHECK_SSO_URL, transformCheckForBE(data));

  return Promise.resolve(ajax);
}

function login(data) {
  const ajax = $.post(LOGIN_URL, transformLoginForBE(data));

  return Promise.resolve(ajax);
}

function forgot(data) {
  const ajax = $.post(FORGOT_URL, transformForgotForBE(data));

  return Promise.resolve(ajax);
}

export default {
  check,
  login,
  forgot,
};

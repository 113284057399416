import React from 'react';
import PropTypes from 'prop-types';
import { Transition, TransitionGroup } from 'react-transition-group';

function FadeTransition({ step, children }) {
  const commonStyle = {
    transition: 'all 250ms',
    willChange: 'transform',
  };

  const transitionStyle = {
    exiting: { opacity: 0, transitionDuration: '250ms' },
    entering: { opacity: 0, position: 'absolute', transform: 'translateY(-15px)' },
    entered: { opacity: 1, transform: 'translateY(0)' },
  };

  return (
    <TransitionGroup component={null}>
      <Transition
        mountOnEnter
        unmountOnExit
        key={step}
        timeout={{
          enter: 250,
          exit: 250,
        }}
      >
        {(state) => {
          if (state === 'exited') return null;

          return React.cloneElement(
            children, { style: { ...commonStyle, ...transitionStyle[state] } }
          );
        }}
      </Transition>
    </TransitionGroup>
  );
}

FadeTransition.propTypes = {
  step: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export default FadeTransition;

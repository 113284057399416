import 'url-search-params-polyfill';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import Login from 'components/Login';
import Notification from 'components/core/Notification';

const container = document.getElementById('login');

if (container) {
  ReactDOM.render(
    <Suspense fallback={null}>
      <Notification />
      <Login />
    </Suspense>,
    container
  );
}

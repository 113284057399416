import { useEffect, useState, useCallback } from 'react';
import { get } from 'lodash';

import { currentYear } from 'utils/date';
import { queryParser } from 'utils/query-string';
import { EMAIL_REGEXP } from 'utils/validation';

import api from './api';
import {
  MAIN_URL,
  STEP_FORGOT,
  STEP_PASSWORD,
  STEP_IDENTIFIER,
  successNotifications,
  commonErrorAlert,
  updateSearchParams,
  redirectWithPost,
  getNameFromEmail,
  getInitialData,
  getUrlData,
} from '.';

export default function useLogin() {
  const { step, email } = queryParser(window.location.search);
  const [data, setData] = useState(() => getInitialData({ step, email }));

  const onBrowserBackOrForward = useCallback(() => {
    const { step: currentUrlStep, email: currentUrlEmail } = queryParser(window.location.search);

    return setData(prev => ({
      ...prev,
      ...getUrlData({
        step: currentUrlStep,
        email: currentUrlEmail,
      }),
    }));
  }, []);

  const onEmailChange = useCallback(({ target: { value = '' } }) => setData(
    prev => ({ ...prev, email: value.trim(), isError: false })
  ), []);

  const onPassChange = useCallback(({ target: { value } }) => setData(
    prev => ({ ...prev, password: value, isError: false })
  ), []);

  const onEmailSubmit = useCallback((event) => {
    event.preventDefault();

    const isValid = EMAIL_REGEXP.test(data.email);

    setData(prev => ({
      ...prev,
      isLoading: isValid,
      isError: !isValid,
      ...(isValid && { name: getNameFromEmail(data.email) }),
    }));

    const onSuccess = ({ sso_url }) => {
      if (!!sso_url) {
        redirectWithPost(sso_url);
      } else {
        setData((prev) => {
          updateSearchParams({ params: { step: STEP_PASSWORD, email: prev.email } });

          return ({
            ...prev,
            isLoading: false,
            step: STEP_PASSWORD,
          });
        });
      }
    };

    const onFailure = () => {
      setData(prev => ({ ...prev, isLoading: false, step: STEP_PASSWORD }));
    };

    isValid && api.check(data)
      .then(onSuccess)
      .catch(onFailure);
  }, [data.email]);

  const onPassSubmit = useCallback((event) => {
    event.preventDefault();

    setData(prev => ({ ...prev, isLoading: true }));

    const onSuccess = (payload = {}) => {
      const redirectTo = get(payload, 'redirect_to', MAIN_URL);
      window.location.href = redirectTo;
    };

    const onFailure = ({ responseText }) => {
      setData(prev => ({ ...prev, isLoading: false, isError: true }));
      commonErrorAlert(responseText);
    };

    api.login(data)
      .then(onSuccess)
      .catch(onFailure);
  }, [data.password]);

  const onForgotSubmit = useCallback((event) => {
    event.preventDefault();

    setData(prev => ({ ...prev, isLoading: true }));

    const onSuccess = () => {
      setData((prev) => {
        updateSearchParams({ params: { step: STEP_IDENTIFIER, email: prev.email } });

        return ({
          ...prev,
          isLoading: false,
          step: STEP_IDENTIFIER,
        });
      });
      successNotifications.reset();
    };

    const onFailure = ({ responseText }) => {
      setData(prev => ({ ...prev, isLoading: false }));
      commonErrorAlert(responseText);
    };

    api.forgot(data)
      .then(onSuccess)
      .catch(onFailure);
  }, [data.email]);

  const onGoToForgot = useCallback(() => setData((prev) => {
    updateSearchParams({ params: { step: STEP_FORGOT, email: prev.email } });

    return ({
      ...prev,
      isError: false,
      step: STEP_FORGOT,
    });
  }), []);

  const onGoToStart = useCallback(() => setData((prev) => {
    updateSearchParams({ params: { step: STEP_IDENTIFIER, email: prev.email } });

    return ({
      ...prev,
      isError: false,
      step: STEP_IDENTIFIER,
    });
  }), []);

  useEffect(() => {
    if (data.step !== step) updateSearchParams({ params: { step: data.step } });
  }, []);

  useEffect(() => {
    window.addEventListener('popstate', onBrowserBackOrForward);
    return () => window.removeEventListener('popstate', onBrowserBackOrForward);
  });

  return {
    data,
    year: currentYear(),
    onEmailChange,
    onEmailSubmit,
    onPassChange,
    onPassSubmit,
    onForgotSubmit,
    onGoToForgot,
    onGoToStart,
  };
}

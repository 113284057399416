import React from 'react';

import { alertErrorAction, alertSuccessAction } from 'components/core/Notification';
import styles from 'components/Login/styles.module.scss';

export function commonSuccessAlert(content) {
  alertSuccessAction({
    content: (
      <div className={styles.notifications}>
        {content}
      </div>
    ),
  });
}

export function commonErrorAlert(content = 'Something went wrong. Please try again.') {
  alertErrorAction({
    content: <div className={styles.notifications} dangerouslySetInnerHTML={{ __html: content }} />,
  });
}

export const successNotifications = {
  reset: () => commonSuccessAlert((
    <>
      <div>An email has been sent to your address</div>
      <div>Please follow the link in your email to reset your password</div>
    </>
  )),
  logout: () => commonSuccessAlert((
    <div>Signed out successfully</div>
  )),
};

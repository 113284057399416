import { isEmpty, join, omitBy } from 'lodash';

import { queryStringify } from 'utils/query-string';

import { STEP_IDENTIFIER, initialData } from './constants';

export * from './notifications';
export * from './transformers';
export * from './constants';
export * from './shapes';

export function getNameFromEmail(email = '') {
  return email.slice(0, email.indexOf('@'));
}

export function getUrlData({ step, email = '' }) {
  return {
    email,
    name: getNameFromEmail(email),
    step: (email && step) ? step : STEP_IDENTIFIER,
  };
}

export function getInitialData({ step, email }) {
  return {
    ...initialData,
    ...getUrlData({ step, email }),
  };
}

export function updateSearchParams({
  location = window.location,
  history = window.history,
  params,
}) {
  const { pathname } = location;
  const query = queryStringify(omitBy(params, isEmpty));
  const path = join([pathname, query], '?');

  history.pushState({ path }, null, path);
}

export function redirectWithPost(url) {
  const form = document.createElement('form');
  const tokenInput = document.createElement('input');
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  tokenInput.name = 'authenticity_token';
  tokenInput.value = csrfToken;
  form.method = 'POST';
  form.hidden = true;
  form.action = url;

  form.appendChild(tokenInput);
  document.body.appendChild(form);

  form.submit();
}
